/* eslint-disable */
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Head from "next/head"
import { useRouter } from "next/router"
import NProgress from "nprogress"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {
  routeKeys,
  buttonRouteKeys,
  policySchemaKeys
} from "ui/data/personal/homeowners/constants/keys"
import {
  storeKeys,
  flowKeys,
  axiosKeys,
  PolicyLogHeader,
  commonKeys,
  apolloKeys
} from "ui/data/constants/keys"
import { labels } from "ui/data/constants/labels"
import { alertMsgs } from "ui/data/personal/homeowners/constants/messages"
import {
  validateLogin,
  fetchQueriesAndFragements,
  setPolicy,
  setData,
  save
} from "@cogitate/ui-utils-core-test"
import {
  fetchActionConfig,
  fetchRoleAuthorization,
  fetchUISchema
} from "@cogitate/ui-utils-core-test/dist/utilities/shared/schemafromblob"
import { getInitalState } from "ui/components/initialData.js"
import { staticItems } from "ui/data/personal/homeowners/constants/static"
import { loadMastersByContainer } from "@cogitate/ui-utils-core-test/dist/utilities/shared/masters"
import { masterReducer } from "ui/data/constants/masterReducer"
import {
  getStoreState,
  setStoreState
} from "@cogitate/ui-utils-core-test/dist/utilities/pages/shared"
import { callAPI } from "@cogitate/ui-utils-core-test/dist/utilities/shared/helpers"
import { fetchmenu, getHeaders, setUserRole } from "ui/utilities/helper"
import moment from "moment"
import { LoadPolicyDetail } from "@cogitate/ui-utils-core-test/dist/utilities/apollo/operations"
import { useDispatch, useSelector } from "react-redux"
const {
  PUT_POLICY
} = require("@cogitate/ui-utils-core-test/dist/store/types/actions")
import _ from "lodash"

export default function Login() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { logout } = router.query
  const [lastBuild, setLastBuild] = useState(null)

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href)
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href)
    })
    if (logout == 1) toast.success(alertMsgs.Shared.LogOut)
    else if (logout == 0) toast.error(alertMsgs.Shared.SomethingWentWrong)
    if (Object.keys(router.query).length) {
      router.replace({
        query: {}
      })
    }
  }, [logout])

  const onFormSubmit = async (event) => {
    event.preventDefault()
    NProgress.start()
    var { username, password } = document.forms[0]
    var resp = await validateLogin(username.value, password.value)

    if (resp) {
      try {
        await setData({
          buttonRouteKeys,
          routeKeys,
          staticItems,
          policySchemaKeys,
          PolicyLogHeader
        })

        // Grouping all the concurrent async operations
        {
          /* eslint-disable-next-line */
        }
        await Promise.all([
          loadMastersByContainer(),
          setPolicy(getInitalState()),
          fetchQueriesAndFragements(),
          fetchRoleAuthorization(),
          fetchvalidation(),
          fetchActionConfig(),
          fetchmenu(),
          fetchUISchema(
            process.env.NEXT_PUBLIC_CLIENT,
            process.env.NEXT_PUBLIC_DEFAULT_LINE,
            process.env.NEXT_PUBLIC_LINE_OF_BUSINESS,
            process.env.NEXT_PUBLIC_DEFAULT_STATE.toLowerCase(),
            process.env.NEXT_PUBLIC_DEFAULT_CARRIER.toLowerCase(),
            [
              flowKeys.Quote.toLowerCase(),
              flowKeys.Application.toLowerCase(),
              flowKeys.Endorsement.toLowerCase(),
              flowKeys.Modals.toLowerCase(),
              flowKeys.AccountHub.toLowerCase()
            ],
            process.env.NEXT_PUBLIC_DEFAULT_VERSION.toLowerCase()
          )
        ])

        const masterData = getStoreState(
          storeKeys.MasterReducer
        ).StaticAgenciesUWAgent
        if (masterData?.length) {
          setStoreState(storeKeys.MasterReducer, masterData[0])
        } else {
          setStoreState(storeKeys.MasterReducer, masterReducer)
        }
        setStoreState(
          storeKeys.PolicyReducer,
          setUserRole(
            getStoreState(storeKeys.PolicyReducer),
            getStoreState(storeKeys.LoggedInUserReducer),
            getStoreState(storeKeys.MasterReducer)
          )
        )
        await reviewHippoPolicy()
      } catch (err) {
        console.error("Error loading data: ", err)
        toast.error(alertMsgs.Login.LoadingFailed)
      } finally {
        NProgress.done()
      }
    } else {
      toast.error(alertMsgs.Login.LoginFailed)
      NProgress.done()
    }
  }

  const reviewHippoPolicy = async () => {
    try {
      const search = window.location.search
      const params = new URLSearchParams(search)
      const QuoteNumber = params.get("QuoteNumber")
      if (QuoteNumber) {
        let resp = await LoadPolicyDetail(QuoteNumber)
        if (
          resp &&
          resp[0] &&
          resp[0].Attributes?.AppSource === "Hippo" &&
          resp[0].NonFunctional?.LastSubmittedPage === routeKeys.HIPPO
        ) {
          let policy = _.cloneDeep(resp[0])
          policy.NonFunctional.LastSubmittedPage = routeKeys.HIPPOUpdatePolicy
          policy = setUserRole(
            policy,
            getStoreState(storeKeys.LoggedInUserReducer),
            getStoreState(storeKeys.MasterReducer)
          )
          policy = await save(
            policy,
            apolloKeys.DummyMutation,
            routeKeys.HIPPOUpdatePolicy,
            commonKeys.EmptyString
          )
          dispatch({ type: PUT_POLICY, payload: policy })
          router.push(routeKeys.ApplicationPolicyHolder.toLowerCase())
        } else {
          router.push(routeKeys.DashboardAccountHub.toLowerCase())
        }
      } else {
        router.push(routeKeys.DashboardAccountHub.toLowerCase())
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  const fetchvalidation = async () => {
    try {
      const url = `${process.env.NEXT_PUBLIC_GQL_END_POINT_JSON_SCHEMA}?BLOB_CONTAINER_NAME=${process.env.NEXT_PUBLIC_AZURE_STORAGE_ACCOUNT_NAME}&BLOB_HOOKSCHEMA_FILE_PATH=${process.env.NEXT_PUBLIC_CLIENT}/configs/${process.env.NEXT_PUBLIC_LINE}/${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}/validations.json`
      const validationJson = await callAPI(
        axiosKeys.GetAction,
        url,
        null,
        getHeaders()
      )
      return setStoreState(storeKeys.DataReducer, {
        validationJson: validationJson
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  const getHeaders = (headers = {}) => {
    const authenticatedUser = getStoreState(storeKeys.LoggedInUserReducer)
    headers = {
      ...headers,
      Authorization: "Bearer " + authenticatedUser.encodedJWT || null
    }
    return headers
  }

  return (
    <>
      <Head>
        <title>{labels.Shared.Login.PageTitle}</title>
        <meta name="description" content="Developed By Team Cogitate" />
        <link
          rel="icon"
          href={`${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/favicon-embark.png`}
        />
      </Head>
      <div className="wrapper bg-image">
        <div className="login-page-content">
          <div className="left-panel">
            <div className="left-panel-content">
              <div className="login-ca-logo">
                <img
                  src={`${process.env.NEXT_PUBLIC_CDN_IMAGE_PATH}/${process.env.NEXT_PUBLIC_AZURE_IMAGE_STYLE_STORAGE_ACCOUNT_NAME}/${process.env.NEXT_PUBLIC_CLIENT}/${process.env.NEXT_PUBLIC_ASSESTS_IMAGES}/logo.png`}
                  className="headerLogo"
                  alt="Cogitate"
                />
              </div>
              <div className="left-panel-child">
                <div className="login-ca-txt">
                  <span className="logoHead1">Personal</span>
                  <span className="logoHead2">
                    {process.env.NEXT_PUBLIC_LOB}
                  </span>
                </div>
              </div>
            </div>
            <div className="company-rights">
              <p>
                {moment(new Date()).get("Y")} @
                <a
                  href="https://www.cogitate.us"
                  rel="noreferrer"
                  target="_blank"
                >
                  Cogitate Technology Solutions
                </a>
                , All Rights Reserved{" "}
                <span style={{ color: "red" }}>{`(BUILD NUMBER : ${
                  lastBuild ? lastBuild : process.env.NEXT_PUBLIC_BUILD_NUMBER
                })`}</span>
              </p>
            </div>
          </div>
          <div className="right-panel">
            <form className="login-form" onSubmit={onFormSubmit}>
              <div className="form-header mb-3">Login</div>
              <div className="input-group">
                <span className="input-group-addon">
                  <FontAwesomeIcon icon={faUser} />
                </span>
                <input
                  type="text"
                  placeholder="Username"
                  name="username"
                  className="form-control"
                  required
                />
              </div>
              <div className="input-group">
                <span className="input-group-addon">
                  <FontAwesomeIcon icon={faLock} />
                </span>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  className="form-control"
                  required
                />
              </div>
              <input type="submit" className="buttonLogin" value="Sign In" />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

Login.layout = "L1"
